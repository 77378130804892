import React, {useState} from "react";
import SdkNavigate from "../components/section/sdk-navigate";
import { Button } from "react-bootstrap";
// Images
import MiddleArrow from '../assets/images/flow/f2-arrow.png';
import MiddleArrDn from '../assets/images/flow/f2-arrow-down.png';
import AiBoy from '../assets/images/flow/f2-ai.png';
import IdeaSection from "../components/section/idea-section";
import ModalVideo from "../components/modal/video-banner";

const ForSellers = () => {
  const [modalToggle, setModalToggle] = useState({video: false});

  const toggleModal = (e, modal_type, toggleState) => {
    try{
      if(toggleState){
        e.preventDefault();
      }
      setModalToggle({...modalToggle, [modal_type]: toggleState});
    }catch(error){
      console.log(error)
    }
  }
  return (
    <>
    <section className="seller-sec mt-btm-header">
      <div className="circle-blue"></div>
      <div className="circle-pink"></div>
        <div className="container-fluid container-lg">
          {/* Heading */}
          <div className="row heading text-center justify-content-center">
            <div className="col-lg-10 col-12 col-sm-12">
              <h1 className="title title-gap">
              Tap into new <span className="highlight">customer segments</span> and generate <span className="curl">additional revenue</span>
              </h1>
              <div className="d-flex justify-content-center w-100">
                <p className="sub-heading">Simplify international travel by integrating on-demand services into your app, making every journey delightful!</p>
              </div>
              <Button
                className=""
                variant="primary"
                onClick={(e) => toggleModal(e, 'video', true)}
              >
                See SuperGryd in Action
              </Button>
      
              {/* <h1 className="title text-center title-gap">
              <span className="tagline">We streamline, you expand</span><br/>
              Seamlessly connect your travelers to <br/><span className="h-word">on-demand</span> services in <span className="highlight">international destinations</span><br/>
                
                <span>
                  <img
                    // className="arrow float-end"
                    className="arrow-fxd float-end"
                    loading="lazy"
                    alt="Arrow"
                    src={require("../assets/images/arrow.png")}
                  />
                </span>
              </h1> */}
            </div>
          </div>
          {/* <div className="row heading">
            <div className="col-lg-12 col-12 col-sm-12">
              <h1 className="text-center">
              <span className="tagline">Your Services, Our Network</span><br/>
              Tap into new customer segments and generate <span className="highlight">additional revenue</span><br/>
                <span>
                  <img
                    // className="arrow float-end"
                    className="arrow-fxd float-end"
                    loading="lazy"
                    alt="Arrow"
                    src={require("../assets/images/arrow.png")}
                  />
                </span>
              </h1>
            </div>
          </div> */}
          {/* Flow */}
          <div className="justify-content-center row align-items-center text-center flow2-content ms-0 me-0">
            <div className="col-12 col-sm-4 col-md-3 col-lg-2 d-none d-md-flex">
              <div>
                <div className="box"><span>Ride Hailing</span> <img src={require('../assets/images/icon/taxi.svg').default} /></div>
                <div className="box"><span>Food Ordering</span> <img src={require('../assets/images/icon/fast-food.svg').default} /></div>
                <div className="box"><span>Medical Services</span> <img src={require('../assets/images/icon/hospital.svg').default} /></div>
                <div className="box"><span>Experiences</span> <img src={require('../assets/images/icon/hotel.svg').default} /></div>
                <div className="box mb-0"><span>And many more...</span></div>               
              </div>
            </div>
            <div className="col-12 col-sm-8 col-md-3 col-lg-2 d-flex d-md-none justify-content-center">
              <div>
                <div className="box-content">
                  <div className="box"><span>Ride Hailing</span> <img src={require('../assets/images/icon/taxi.svg').default} /></div>
                  <div className="box"><span>Food Ordering</span> <img src={require('../assets/images/icon/fast-food.svg').default} /></div>
                </div>
                <div className="box-content">
                  <div className="box"><span>Medical Services</span> <img src={require('../assets/images/icon/hospital.svg').default} /></div>
                  <div className="box"><span>Experiences</span> <img src={require('../assets/images/icon/hotel.svg').default} /></div>
                </div>
                <div className="box-content justify-content-center"><div className="box"><span>And many more...</span></div></div>        
              </div>
            </div>
            <div className="col-12 col-sm-7 col-md-6">
              <img src={MiddleArrow} alt="SuperGryd Services" className="d-none d-md-flex w-100"/>
              <img src={MiddleArrDn} alt="SuperGryd Services" className="d-md-none w-100"/>
            </div>
            <div className="col-8 col-sm-6 col-md-3 col-lg-2 mt-3 mt-md-0">
              <img src={AiBoy} alt="SuperGryd APP" className="w-100"/>
            </div>
          </div>
        </div>
      </section>
      {/* SDk */}
      <SdkNavigate
        content={"SuperGryd uses the API documentation you provide to seamlessly integrate your services with our platform. This allows your business to connect with top consumer apps and reach millions of international travelers. Experience effortless integration and expand your global reach in weeks, not months!"}
      />
      {/* Opportunity */}
      <section>
      <div className="container-fluid container-sm opper-sec"> 
        {/* <h2 className="text-center title">Expand Your Reach <br/><span>Increase Your Revenue</span></h2> */}
        <div className="row mt-md-3 justify-content-center">
          <div className="col-lg-5 col-12 col-sm-12">
            <h2 className="text-center title pt-0">Expand Your Reach <span className="highlight curl">Increase Your Revenue</span></h2>
          </div>
        </div>
        <div className="row mt-md-3 justify-content-center">
          <div className="col-lg-4 col-10 col-sm-10">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3">
              <div className="bg-c-gradient oval-circle-pi"></div>
              <div className="bg-c-gradient oval-circle-bl"></div>
              <img src={require('../assets/images/oppertunity/insight.svg').default} />
              <h3>Leverage Data-Driven Insights</h3>
              <p>Create unique, targeted offerings that resonate with your audience</p>
            </div>
          </div>
          <div className="col-lg-4 col-10 col-sm-10">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3">
              <div className="bg-c-gradient oval-circle-pi"></div>
              <div className="bg-c-gradient oval-circle-bl"></div>
              <img src={require('../assets/images/oppertunity/world.svg').default} />
              <h3>International Expansion at no extra cost</h3>
              <p>Reach international customers without any additional costs</p>
            </div>
          </div>
          <div className="col-lg-4 col-10 col-sm-10">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3">
              <div className="bg-c-gradient oval-circle-pi"></div>
              <div className="bg-c-gradient oval-circle-bl"></div>
              <img src={require('../assets/images/oppertunity/revenue.svg').default} />
              <h3>Increase revenue and drive growth</h3>
              <p>Increase your revenue at no extra tech and marketing costs</p>
            </div>
          </div>
        </div>
      </div>
      </section>
      <IdeaSection />
      <ModalVideo
        isShow={modalToggle.video}
        toggleModal={(e, state) => toggleModal(e, 'video', state)}
      />
    </>
  );
};

export default ForSellers;
