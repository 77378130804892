import { Button } from "react-bootstrap";

const Partner = () => {
  return (
    <section>
      <div className="container-fluid container-lg partner">
        <h2 className="title">How would you like to <span className="curl highlight">partner with us?</span></h2>
        <div className="row mt-md-4 justify-content-center">
          <div className="col-md-12 col-12 col-sm-12">
            {/* <div className="me-lg-2"> */}
            <div className="row p-container">
              <div className="border-circle circle-pnk"></div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-12 col-sm-6">
                <div className="content">
                <h3>As a Host</h3>
                <p>Add our network of ridehailing, travel experiences, food partners to your app, so your users can use it abroad.</p>
                <a href="/hosts">
                  <Button
                    variant="primary"
                    size="lg"
                  >Partner as a host</Button>
                </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-1 col-md-1 col-6 col-sm-1"></div>
              <div className="col-xl-4 col-lg-6 col-md-5 col-6 col-sm-5 pe-0 img-content">
                {/* <div className="img-content"> */}
                  <img alt="Go Global" src={require("../../assets/images/partner/host.png")}/>
                {/* </div> */}
              </div>
            </div>
            
            {/* </div> */}
          </div>
          <div className="col-md-12 col-12 col-sm-12">
            {/* <div className="ms-lg-2"> */}
            <div className="row p-container mb-0">
              <div className="col-xl-5 col-lg-6 col-6 col-md-5 col-sm-5 ps-0 pe-0 img-content order-2 order-sm-0">
                {/* <div className="img-content"> */}
                  <img loading="lazy" alt="Expand Globally" className="d-sm-flex d-none" src={require("../../assets/images/partner/seller.png")} />
                  <img loading="lazy" alt="Expand Globally" className="d-flex d-sm-none" src={require("../../assets/images/partner/seller-mob.png")} />
                {/* </div> */}
              </div>
              <div className="col-xl-2 col-lg-1 col-md-1 col-sm-1 col-6 order-1 order-sm-1"></div>
              <div className="col-xl-5 col-lg-5 col-12 col-md-6 col-sm-6 order-0 order-sm-2">
                <div className="content">
                <h3>As a Seller</h3>
                <p className="pe-0 pe-md-5">You provide services and receive additional revenue at zero user acquisition cost.</p>
                <a href="/sellers">
                  <Button
                    variant="primary"
                    size="lg"
                  >Partner as a Seller</Button>
                </a>
                </div>
              </div>
              <div className="border-circle circle-blu"></div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partner;