import { Button } from "react-bootstrap";

const IdeaSection = () => {
  return (
    <>
     <section className="">
        <div className="container-fluid container-lg idea-con">
          <div className="row justify-content-center text-center">
            <div className="col-10 col-sm-8 col-md-6 col-lg-4">
            <div className="bg-c-gradient oval-circle-bl"></div>
              <h2 className="pb-3 pt-0">Got Another <span className="curl highlight">Idea?</span></h2>
              <p className="content-lgt-md ps-md-2 pe-md-2">Let's discuss it! Share your thoughts and we'll work together to bring them to life.</p>
              <a href="#contactUs">
              <Button
                className="mt-4"
                variant="primary"
                // variant="outline-primary"
                // size="lg"
              >Talk to us</Button>
              </a>
              <div className="bg-c-gradient oval-circle-blu"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IdeaSection;
