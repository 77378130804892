import React, {useState} from "react";
import { Button } from "react-bootstrap";
// Images
import MobileCons from '../assets/images/flow/consumer-app.png';
import MobileMulti from '../assets/images/flow/mobile.png';
import SdkNavigate from "../components/section/sdk-navigate";
import '../assets/css/pages/host.css?v=2';
import IdeaSection from "../components/section/idea-section";
import ModalVideo from "../components/modal/video-banner";
import ServicesCircle from "../components/section/services-circle";

const ForHosts = () => {
  const [modalToggle, setModalToggle] = useState({video: false});

  const toggleModal = (e, modal_type, toggleState) => {
    try{
      if(toggleState){
        e.preventDefault();
      }
      setModalToggle({...modalToggle, [modal_type]: toggleState});
    }catch(error){
      console.log(error)
    }
  }

  return (
    <>
      <section className="host-sec mt-btm-header">
        <div className="circle-blue"></div>
        <div className="circle-pink"></div>
        <div className="container-fluid container-lg">
          {/* Heading */}
          <div className="row heading text-center">
            <div className="col-lg-12 col-12 col-sm-12">
              <h1 className="title title-gap">
              Seamlessly connect your travelers to <span className="highlight">on-demand services</span> <span className="curl">internationally</span>
              </h1>
              <div className="d-flex justify-content-center w-100">
                <p className="sub-heading">Simplify international travel by integrating on-demand services into your app, making every journey delightful!</p>
              </div>
              {/* <a href="#contactUs" className="justify-content-center d-flex"> */}
                <Button
                  className=""
                  variant="primary"
                  onClick={(e) => toggleModal(e, 'video', true)}
                >
                  See SuperGryd in Action
                </Button>
              {/* </a> */}
              {/* <h1 className="title text-center title-gap">
              <span className="tagline">We streamline, you expand</span><br/>
              Seamlessly connect your travelers to <br/><span className="h-word">on-demand</span> services in <span className="highlight">international destinations</span><br/>
                
                <span>
                  <img
                    // className="arrow float-end"
                    className="arrow-fxd float-end"
                    loading="lazy"
                    alt="Arrow"
                    src={require("../assets/images/arrow.png")}
                  />
                </span>
              </h1> */}
            </div>
          </div>
           {/* Flow */}
           <div className="justify-content-center row align-items-center text-center flow-content">
            {/* <div className="col-md-12 col-12"> */}
              {/* <div > */}
                {/* <div className=""> */}
                <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 consumer-app">
                  <div className="bg-c-gradient sm-circle-pnk"></div>
                  <img src={MobileCons} alt="Consumer APP"/>
                  {/* <img src={require('../assets/images/flow/consumer-app-k.png')} /> */}
                </div>
                <div className="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 circle-rotate">
                  {/* <img src={MiddleImg} alt="SuperGryd Services" className="d-none d-sm-flex w-100"/>
                  <img src={MiddleImgMob} alt="SuperGryd Services" className="d-sm-none w-100"/> */}
                  <div className="services-circle">
                    <img src={require('../assets/images/flow/arrow-right.png')} alt="Arrow" className="arrow"/>
                    {/* <img src={require('../assets/images/flow/arrow-right.png')} alt="Arrow" className="arrow d-none d-md-flex"/>
                    <img src={require('../assets/images/flow/arrow-down.png')} alt="Arrow" className="arrow d-block d-md-none"/> */}
                    <ServicesCircle />
                  </div>
                  
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 p-sm-0 native-app">
                  <div className="circle-sm-bg-pink"></div>
                  <div className="circle-sm-bg-blue"></div>
                  <img src={MobileMulti} alt="SuperGryd APP"/>
                </div>
                {/* </div> */}
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </section>
      {/* SDk */}
      <SdkNavigate
        content={"SuperGryd offers a fully customizable SDK to seamlessly integrate a range of on-demand services directly into your app, empowering you to provide enhanced user experiences without the complexity. This no-code integration takes just a few weeks instead of months!"}
      />
      {/* Opportunity */}
      <section>
      <div className="container-fluid container-sm opper-sec"> 
        <div className="row mt-md-3 justify-content-center">
          <div className="col-lg-6 col-12 col-sm-12">
            <h2 className="text-center title">Opportunity Gains for the <span className="highlight curl">Consumer Brand</span></h2>
          </div>
        </div>
        <div className="row mt-md-3 justify-content-center">
          <div className="col-lg-4 col-10 col-sm-10 p-lg-2">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3 first-opp test">
              <div className="bg-c-gradient oval-circle-pi"></div>
              <div className="bg-c-gradient oval-circle-bl"></div>
              <img src={require('../assets/images/oppertunity/revenue.svg').default} />
              <h3>Incremental Revenue Growth</h3>
              <p>Unlock new revenue streams and boost your profitability</p>
            </div>
          </div>
          <div className="col-lg-4 col-10 col-sm-10 p-lg-2">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3">
              <div className="bg-c-gradient oval-circle-pi"></div>
              <div className="bg-c-gradient oval-circle-bl"></div>
              <img src={require('../assets/images/oppertunity/arrow.svg').default} />
              <h3>Expanded Vertical & Horizontal Reach</h3>
              <p>Broaden your market presence and explore new growth avenues</p>
            </div>
          </div>
          <div className="col-lg-4 col-10 col-sm-10 p-lg-2">
            <div className="card mb-4 mb-lg-0 me-md-3 ms-md-3">
              <div className="bg-c-gradient oval-circle-pi"></div>
              <div className="bg-c-gradient oval-circle-bl"></div>
              <img src={require('../assets/images/oppertunity/journey.svg').default} />
              <h3>Complete Customer Journeys & Experiences</h3>
              <p>Enhance customer satisfaction and build strong loyalty</p>
            </div>
          </div>
        </div>
      </div>
      </section>
      {/* Idea */}
      <IdeaSection />
      <ModalVideo
        isShow={modalToggle.video}
        toggleModal={(e, state) => toggleModal(e, 'video', state)}
      />
    </>
  );
};

export default ForHosts;  
