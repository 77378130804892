const ServicesCircle = () => {
  return (
    <>
        <div className="c-rotate-container">
            <div className="circle-icon">
            {/* <img src={require('../../assets/images/flow/arrow-right.png')} alt="Arrow"/> */}
            <div className="item">
                <img
                alt="Car"
                src={require("../../assets/images/icon/taxi.svg").default}
                />
            </div>
            <div className="item">
                <img
                className="icon-sm"
                alt="Food"
                src={require("../../assets/images/icon/food-i.svg").default}
                />
            </div>
            <div className="item">
                <img
                className="icon-sm"
                alt="Medical"
                src={require("../../assets/images/icon/medical.svg").default}
                />
            </div>
            <div className="item">
                <img
                className="icon-sm"
                alt="Train"
                src={require("../../assets/images/icon/train.svg").default}
                />
            </div>
            <div className="item">
                <img
                className="icon-sm"
                alt="Payment"
                src={require("../../assets/images/icon/credit-card.svg").default}
                />
            </div>
            <div className="item">
                <img
                className="icon-sm"
                alt="Experience"
                src={require("../../assets/images/icon/experience.svg").default}
                />
            </div>
            <div className="inner-circle">
                <div className="first border-circle m-auto align-content-center">
                <div className="second border-circle m-auto align-content-center">
                    <div className="third border-circle m-auto align-content-center">
                    <img alt="Logo" src={require("../../assets/images/logo-hd.png")} />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </>
  );
};

export default ServicesCircle;
