import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import WrapMaster from "./components/wrapper/main";
import LandingPage from "./pages/LandingPage";
import ForSellers from "./pages/ForSellers";
import AboutUs from "./pages/AboutUs";
import ForHosts from "./pages/ForHosts";
import PrivacyPolicy from "./pages/static/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/css/style.css?v=2';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/sellers":
        title = "For Sellers";
        metaDescription = "";
        break;
      case "/about-us":
        title = "About Us";
        metaDescription = "";
        break;
      case "/for-hosts":
        title = "For Hosts";
        metaDescription = "";
        break;
      case "/contact-us":
        title = "Contact Us";
        metaDescription = "";
        break;
      case "/privacy-policy":
        title = "Privacy Policy";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = `${title} | SuperGryd`;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path='*' element={<NotFound/>} />
      <Route path="/" element={<WrapMaster><LandingPage/></WrapMaster>}/>
      <Route path="/sellers" element={<WrapMaster><ForSellers/></WrapMaster>}/>
      <Route path="/about-us" element={<WrapMaster><AboutUs/></WrapMaster>}/>
      <Route path="/hosts" element={<WrapMaster><ForHosts/></WrapMaster>}/>
      <Route path="/privacy-policy" element={<WrapMaster showFaq={false} showContact={false}><PrivacyPolicy/></WrapMaster>}/>
      <Route path="/contact-us" element={<WrapMaster showFaq={false} showContact={false}><ContactUs/></WrapMaster>}/>
    </Routes>
  );
}
export default App;
