import React, {useState, useEffect} from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import Faq from '../common/faq';
import ContactForm from '../common/contact-form';
// import Contact from '../common/contact';

function WrapMaster(props) {
  const [isLoading, setisLoading] = useState(true);
  // This will run one time after the component mounts
  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      // console.log('page loaded');
      // do something else
      setisLoading(false);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

    return (
      <>
      {isLoading
      ?
      <div className="preloader">
        <div className="pre-loader"></div>
      </div>
      :
      <>
       <div className="header header-scrolled" id="header">
            <div className="container-fluid container-lg">
                {/* Header */}
                <Header isOval={false} />
            </div>
        </div>
        {props.children}
        {/* FAQ */}
        {(typeof props.showFaq === "undefined" || props.showFaq === true) && (
          <Faq />
        )}
        {/* Contact Form */}
        {(typeof props.showContact === "undefined" || props.showContact === true) && (
          <ContactForm />
        )}
        {/* Contact */}
        {/* <Contact /> */}
        {/* Footer */}
        <Footer />
      </>
      }
      </>
    );
  }
  
  export default WrapMaster;